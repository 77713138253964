import ReturnBackButton from 'atoms/ReturnBackButton/ReturnBackButton'
import { useTabTitle } from 'hooks/useTabTitle/useTabTitle'
import useTopNavigationContext from 'hooks/useTopNavigationContext/useTopNavigationContext'
import { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import { useGetInstallation, useUpdateInstallation } from '../api'
import Loading from 'atoms/Loading/Loading'
import ErrorPanel from 'molecules/ErrorPanel'
import { removeToken } from 'common/helpers/removeToken'
import LSDate, { DATE_FORMAT_MMDDYYYYHHMM } from 'common/Date.helpers'
import FileViewer from 'molecules/FileViewer/FileViewer'
import { InstallationComponent, InstallationDetail } from 'common/types/kraken-core/Installation'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  List,
  ListItem,
  ListItemText,
  Grid,
  Card,
  CardContent,
  Tabs,
  Tab,
  CardHeader,
  Stack,
  Typography,
  Box,
} from '@mui/material'
import { useTemplateOnboardDashboardContext } from '../TemplateOnboard/TemplateOnboardDashboardContext'
import InstallationViewParameterFromIntegrationTemplate from './InstallationViewParametersFromIntegrationTemplate'
import { toast } from '../../../atoms/Toast'
import { Breadcrumbs, Button, Link, LoadingDots, Switch, Tag, Text } from '@loadsmart/loadsmart-ui'

export interface InstallationViewProps {
  installationId?: string
}

const COMPNENT_LINK_MAPPING: Record<string, string> = {
  GatewaySettings: '/gateways',
  ConnectionSettings: '/connections',
  ConversionCode: '/conversioncodes',
}

enum INSTALLATION_SOURCES {
  UNKNOWN = 'Unknown',
  INTEGRATION_TEMPLATE = 'Integration Template Onboarding',
}

export default function InstallationView() {
  useTabTitle('Installation View')

  // TODO: We must dynamically identify the source when we have more sources
  const [installationData, setInstallationData] = useState<InstallationDetail>()
  const [installationSource, setInstallationSource] = useState(INSTALLATION_SOURCES.UNKNOWN)
  const onboardingContext = useTemplateOnboardDashboardContext()
  const [parametersRawView, setParametersRawView] = useState(false)
  const { updateState: updateTopNavigationContext } = useTopNavigationContext()
  const [groupedComponents, setGroupedComponents] = useState<
    Record<string, Array<InstallationComponent>>
  >({})
  const [isDirty, setIsDirty] = useState<boolean>(false)
  const handleReinstall = useRef<Function>(() => {})
  const handleSave = useRef<Function>(() => {})
  const [selectedTab, setSelectedTab] = useState(0)

  const { installationId } = useParams<InstallationViewProps>()

  const { data, isLoading: isLoadingDetails, error: errorDetails } = useGetInstallation(
    installationId as any
  )

  const {
    mutate: updateInstallation,
    isLoading: isUpdating,
    isSuccess: isUpdated,
  } = useUpdateInstallation()

  const cardStyle = { borderRadius: '16px' } // Adjust the height and border radius as needed

  handleSave.current = async () => {
    const data: any = {
      ...installationData,
    }
    updateInstallation(data as InstallationDetail)
    setIsDirty(false)
  }

  handleReinstall.current = () => {
    // TODO: Improve this logic in favor of a more dynamic way to decide which the implementation to use
    if (installationSource === INSTALLATION_SOURCES.INTEGRATION_TEMPLATE) {
      // Open new tab with the onboarding page filled with the current parameters
      const queryParams = new URLSearchParams({
        installationId: installationId || '',
        encodedInstallationParameters: btoa(
          data?.parameters ? JSON.stringify(data?.parameters) : '{}'
        ),
      })
      const newPath = `/integration/onboarding/template/${
        onboardingContext?.template?.id
      }/onboard?${queryParams.toString()}`
      window.open(newPath, '_blank')
    }
  }

  function getLinkForModel(model: string) {
    return COMPNENT_LINK_MAPPING[model]
  }

  function groupInstallationComponents() {
    if (data && data.components && data.components.length > 0) {
      const grouped: Record<string, Array<InstallationComponent>> = {}
      data.components.forEach(c => {
        if (grouped[c.model]) {
          grouped[c.model] = [...grouped[c.model], c]
        } else {
          grouped[c.model] = [c]
        }
      })
      setGroupedComponents(grouped)
    }
  }

  function identifySource() {
    let source = INSTALLATION_SOURCES.UNKNOWN

    // TODO: We currently get the onboarding template from the context, but we could change to
    // get directly from the installation entity
    if (onboardingContext && onboardingContext.template)
      source = INSTALLATION_SOURCES.INTEGRATION_TEMPLATE

    setParametersRawView(source === INSTALLATION_SOURCES.UNKNOWN)
    setInstallationSource(source)
  }

  const handleChangeAndUpdate = (key: any, value: any) => {
    setInstallationData((prev: any) =>
      prev
        ? {
            ...prev,
            [key]: value,
          }
        : null
    )
    setIsDirty(true)
  }

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setSelectedTab(newValue)
  }

  useEffect(() => {
    if (isUpdated) {
      toast.success('Updated with success')
    }
  }, [isUpdated])

  useEffect(() => {
    updateTopNavigationContext({
      children: (
        <Grid container data-testid="header" justifyContent="space-between" alignItems="center">
          <Grid item>
            <Box display="flex" alignItems="center">
              <ReturnBackButton />
              <Breadcrumbs
                entries={[
                  {
                    label: 'Installations',
                    active: false,
                  },
                  {
                    label: installationId || '',
                    active: true,
                  },
                ]}
              />
            </Box>
          </Grid>
          <Grid item>
            <Grid container spacing={1} justifyContent="flex-end">
              <Grid item>
                <Button variant="primary" onClick={() => handleReinstall.current()}>
                  Re-install
                </Button>
              </Grid>
              <Grid item>
                {isUpdating && <LoadingDots />}
                {!isUpdating && (
                  <Button
                    variant="primary"
                    disabled={!isDirty}
                    onClick={() => handleSave.current()}
                  >
                    Save
                  </Button>
                )}
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      ),
    })
  }, [installationId, isUpdating, isDirty])

  useEffect(() => {
    if (data) {
      groupInstallationComponents()
      setInstallationData(data)
    }
    identifySource()
  }, [data?.id])

  // TODO: We can implement an dependency-like abstraction
  // In the future we could have many sources and therefore we must
  // aboid having many triggers (useEffect) for each new source
  useEffect(() => {
    identifySource()
  }, [onboardingContext?.template?.id])

  return (
    <>
      {isLoadingDetails ? (
        <Loading className="mt-8 justify-center" />
      ) : errorDetails ? (
        <ErrorPanel error={removeToken(JSON.stringify(errorDetails, null, 2))} />
      ) : (
        !!installationData && (
          <Stack spacing={2}>
            <Grid container justifyContent={'space-between'} alignItems="center">
              <Grid item>
                <Typography variant="h1" sx={{ fontWeight: 'bold' }}>
                  {installationData.name || installationData.installation_type?.toLocaleUpperCase()}
                </Typography>
              </Grid>
              <Grid item>
                <Stack>
                  <Text variant="caption-bold" color="color-neutral">
                    Created at
                  </Text>
                  <Text variant="heading-sm-bold">
                    {LSDate(installationData.created_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                  </Text>
                </Stack>
              </Grid>
              <Grid item>
                <Stack>
                  <Text variant="caption-bold" color="color-neutral">
                    Updated at
                  </Text>
                  <Text variant="heading-sm-bold">
                    {LSDate(installationData.updated_at)?.format(DATE_FORMAT_MMDDYYYYHHMM)}
                  </Text>
                </Stack>
              </Grid>
              <Grid item>
                <Box>
                  <Stack>
                    <Text variant="caption-bold" color="color-neutral">
                      Active
                    </Text>
                    <Switch
                      id="is_active"
                      name="is_active"
                      className="flex justify-center"
                      onToggle={e =>
                        handleChangeAndUpdate('is_active', !installationData.is_active)
                      }
                      active={installationData.is_active}
                    />
                  </Stack>
                </Box>
              </Grid>
            </Grid>

            <Stack>
              <Grid container alignItems="center" justifyContent="space-between" spacing={2}>
                <Grid item xs={12} md={6}>
                  <Card style={cardStyle}>
                    <CardHeader title="Party" />
                    <CardContent>
                      <Typography variant="h1" sx={{ fontWeight: 'bold' }}>
                        {installationData.party.name?.slice(0, 20)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={12} md={6}>
                  <Card style={cardStyle}>
                    <CardHeader title="Counterparty" />
                    <CardContent>
                      <Typography variant="h1" sx={{ fontWeight: 'bold' }}>
                        {installationData.counterparty.name?.slice(0, 20)}
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>

              <Grid item />
            </Stack>

            <Card style={cardStyle}>
              <CardContent>
                <Stack spacing={1}>
                  <Tabs value={selectedTab} onChange={handleTabChange}>
                    <Tab label="Parameters" />
                    <Tab label={`Components (${installationData.components?.length})`} />
                  </Tabs>

                  {selectedTab === 0 && (
                    <Stack spacing={2}>
                      <Grid container alignItems="center" spacing={1}>
                        <Grid item>
                          <Switch
                            onToggle={() => setParametersRawView(c => !c)}
                            active={parametersRawView}
                          />
                        </Grid>
                        <Grid item>
                          <Text variant="caption-bold">Raw visualization</Text>
                        </Grid>
                      </Grid>

                      {/* TODO: in the near future, we will have to isolate InstallationView from IntegrationTemplate */}
                      {!parametersRawView &&
                      installationSource === INSTALLATION_SOURCES.INTEGRATION_TEMPLATE ? (
                        <InstallationViewParameterFromIntegrationTemplate
                          installation={installationData}
                        />
                      ) : null}

                      {parametersRawView ? (
                        <FileViewer
                          options={{
                            heigth: 500,
                            readonly: true,
                          }}
                          content={JSON.stringify(installationData.parameters, null, 2)}
                          contentType="application/json"
                        />
                      ) : null}
                    </Stack>
                  )}

                  {selectedTab === 1 && (
                    <Grid container direction="column" className="pt-4">
                      {Object.keys(groupedComponents).map(k => {
                        const name = k
                        const components = groupedComponents[k]
                        return (
                          <Accordion key={k}>
                            <AccordionSummary>
                              <Grid container>
                                <Text>{name}</Text>
                                <Tag variant="accent">{components.length}</Tag>
                              </Grid>
                            </AccordionSummary>
                            <AccordionDetails>
                              <List>
                                {components.map(c => {
                                  return (
                                    <ListItem key={c.id}>
                                      <ListItemText
                                        className="break-all"
                                        style={{
                                          // maxWidth: 100,
                                          alignItems: 'flex-start',
                                          alignContent: 'flex-start',
                                        }}
                                      >
                                        <Link href={`${getLinkForModel(c.model)}/${c.id}`}>
                                          <Text
                                            variant="link"
                                            style={{
                                              whiteSpace: 'break-spaces',
                                              wordBreak: 'normal',
                                              textAlign: 'left',
                                            }}
                                          >
                                            {c.metadata?.name}
                                          </Text>
                                        </Link>
                                      </ListItemText>
                                    </ListItem>
                                  )
                                })}
                              </List>
                            </AccordionDetails>
                          </Accordion>
                        )
                      })}
                    </Grid>
                  )}
                </Stack>
              </CardContent>
            </Card>
          </Stack>
        )
      )}
    </>
  )
}
