import { Route, Switch, useRouteMatch, useParams } from 'react-router'
import TemplateInstallationsList from './TemplateInstallationsList'
import TemplateOnboardDashboardContextWrapper from '../TemplateOnboard/TemplateOnboardDashboardContext'
import InstallationView from './InstallationView'

export interface TemplateInstallationIndexProps {
  templateId: string
}

export default function TemplateInstallationsIndex() {
  const { path } = useRouteMatch()
  const { templateId } = useParams<TemplateInstallationIndexProps>()

  return (
    <Switch>
      <Route exact path={path}>
        <TemplateInstallationsList templateId={templateId} />
      </Route>
      <Route path={`${path}/:installationId`}>
        <TemplateOnboardDashboardContextWrapper templateId={templateId || ''}>
          <InstallationView />
        </TemplateOnboardDashboardContextWrapper>
      </Route>
    </Switch>
  )
}
