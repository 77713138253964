import { useTheme } from '@mui/material'
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import { useMessagesDashboardViewContext } from 'pages/Messages/MessagesDashboardViewContext'
import { useEffect, useState } from 'react'

export interface MessageVolumeSparklineProps {}

export default function MessageVolumeSparkline(props: MessageVolumeSparklineProps) {
  const theme = useTheme()
  const messageViewContext = useMessagesDashboardViewContext()
  const [series, setSeries] = useState<any>()

  useEffect(() => {
    if (!messageViewContext.traffic) {
      return
    }

    const series = new Map<string, Array<Array<any>>>()
    const contextTraffic = messageViewContext.traffic
    contextTraffic.traffic.buckets.forEach(bucket => {
      const dateTime = new Date(bucket.datetime).getTime()
      if (!series.has('total')) {
        series.set('total', [[dateTime, bucket.count]])
      } else {
        const serie = series.get('total') as any
        series.set('total', [...serie, [dateTime, bucket.count]])
      }
    })

    const chartSeries: any = []
    series.forEach((value, key) => {
      chartSeries.push({
        name: key,
        data: value,
        color: '#33CC60',
      })
    })

    setSeries(chartSeries)
  }, [messageViewContext.traffic])

  const options = {
    chart: {
      type: 'spline',
      height: 30,
      width: 200,
      margin: [2, 0, 2, 0],
      backgroundColor: null,
      borderWidth: 0,
      shadow: false,
      style: {
        overflow: 'visible',
      },
      skipClone: true,
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'datetime', // Use datetime for xAxis
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      startOnTick: false,
      endOnTick: false,
      tickPositions: [],
      lineWidth: 0, // Hide xAxis line
    },
    yAxis: {
      endOnTick: false,
      startOnTick: false,
      labels: {
        enabled: false,
      },
      title: {
        text: null,
      },
      tickPositions: [0],
    },
    legend: {
      enabled: false,
    },
    tooltip: {
      hideDelay: 0,
      outside: true,
      shared: true,
    },
    plotOptions: {
      series: {
        animation: true, // Enable animation for smoother transitions
        lineWidth: 2, // Slightly thicker line for better smoothness
        shadow: false,
        states: {
          hover: {
            lineWidth: 2,
          },
        },
        marker: {
          enabled: false, // Hide markers to reduce visual noise
        },
        fillOpacity: 0.25,
        turboThreshold: 5000, // Increase threshold to handle large data
        dataGrouping: {
          enabled: true, // Smooths out high-density data
        },
      },
    },
    credits: {
      enabled: false,
    },
    series,
  }

  return <HighchartsReact highcharts={Highcharts} options={options} />
}
