import { Box } from '@mui/material'
import MessageActionCallResponse from '../MessageDetail/Common/MessageActionFeedbackResponse'
import clsx from 'clsx'
import {
  Button,
  Checkbox,
  Dialog,
  Label,
  Layout,
  Select,
  Tag,
  TextField,
  Text,
} from '@loadsmart/loadsmart-ui'
import { Selectable } from '@loadsmart/loadsmart-ui/dist/hooks/useSelectable'
// eslint-disable-next-line no-use-before-define
import React, { useEffect, useRef, useState } from 'react'
import Icon from 'atoms/Icon'
import { MessageList as MessageListType } from 'common/types/kraken-core/Message'
import Field from 'atoms/Field'
import SimplePagination from 'atoms/SimplePagination'
import EventLike from 'common/types/EventLike'
import DateTimezoneLabel from 'molecules/DateTimezoneLabel/DateTimezoneLabel'
import { useMessagesDashboardViewContext } from '../MessagesDashboardViewContext'
import { useSearchActions } from '../api'
import MessageQuickView from './MessageQuickView'
import { Themes } from '@loadsmart/loadsmart-ui/dist/theming'
import DirectionTag from 'atoms/DirectionTag/DirectionTag'
import analytics, { AnalyticsEvent, AnalyticsEventTrigger } from 'common/analytics'
import MessageDeliveredStatusCompoonent from 'common/components/message/MessageDeliveredStatusComponent'
import MessageStatusTag from '../common/MessageStatusTag'
import TransactionTypeTag from 'atoms/TransactionTypeTag/TransactionTypeTag'
import TradingPartnerTag from 'atoms/TradingPartnerTag/TradingPartnerTag'
import {
  Grid,
  LinearProgress,
  Stack,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  useTheme,
  Tooltip,
} from '@mui/material'
import ClipboardLabel from 'atoms/ClipboardLabel'
import MessageVolumeSparkline from 'molecules/Charts/MessageVolumeSparkline'
import { FEATURE_FLAGS, useAppContext } from 'hooks/useUserContext/appUserContext'

export interface MessageListProps {}

const MAX_MESSAGE_COUNT_VALUE = 10000
const MAX_MESSAGE_COUNT_LABEL = '10K+'

export default function MessageList(props: MessageListProps) {
  const appContext = useAppContext()
  const isTrafficOverviewEnabled = FEATURE_FLAGS.TRAFFIC_OVERVIEW.users.includes(
    appContext.user?.id || ''
  )
  const theme = useTheme()
  const context = useMessagesDashboardViewContext()
  const [isActionArgsModalOpened, setIsActionArgsModalOpened] = useState<boolean>(false)
  const [actionArgs, setActionArgs] = useState<Record<string, string>>({})
  const [showQuickView, setShowQuickView] = useState<boolean>(false)
  const [messageQuickView, setMessageQuickView] = useState<MessageListType>()
  const [actionFeedbackOpen, setActionFeedbackOpen] = useState<boolean>(false)

  const handleSwitchMessageByArrowRef = useRef<Function>()
  const handleHideQuickViewRef = useRef<Function>()

  const { data: actions } = useSearchActions()

  const loading = context.messages.isLoading
  const messagesAsList = context.messages.list || []

  const generatePaginationAnalytics = () => {
    analytics.event({
      category: AnalyticsEvent.MessageDashboardListPaginate,
      action: AnalyticsEventTrigger.click,
    })
  }

  const formatMessageReference = (messageReference: string) => {
    if (!messageReference) {
      return null
    }

    if (messageReference.length > 15) {
      const length = messageReference.length
      return `...${messageReference.slice(length - 15, length)}`
    }
    return messageReference
  }

  const currentPage = () => {
    if (context.messagesPageRequest.index) {
      return context.messagesPageRequest.index
    }
    // First page
    return 1
  }

  const nextPage = () => {
    generatePaginationAnalytics()
    context.updateMessagesPageRequest(context.messagesPageRequest.index + 1)
  }

  const previousPage = () => {
    generatePaginationAnalytics()
    context.updateMessagesPageRequest(context.messagesPageRequest.index - 1)
  }

  const onPage = (pageNumber: number) => {
    generatePaginationAnalytics()
    context.updateMessagesPageRequest(pageNumber)
  }

  const onPageSize = (newPageSize: number) => {
    generatePaginationAnalytics()
    context.updateMessagesPageRequest(1, newPageSize)
  }

  const isMessageSelected = (id: string): boolean =>
    context.messagesActionWapper.selectedMessages.some(m => m.id === id) || false

  const onSelectAllMessages = (e: React.ChangeEvent<HTMLInputElement>) => {
    analytics.event({
      category: AnalyticsEvent.MessageDashboardSelect,
      action: AnalyticsEventTrigger.click,
    })
    if (e.target.checked) {
      context.selectMessages(messagesAsList)
    } else {
      context.selectMessages([])
    }
  }

  const onSelectedMessage = (e: React.ChangeEvent<HTMLInputElement>, message: MessageListType) => {
    let filteredSelectedMessages: MessageListType[] = []

    if (e.target.checked) {
      // Remove duplicated objects
      filteredSelectedMessages = [
        ...(context.messagesActionWapper.selectedMessages || []),
        message,
      ].filter(
        (value: MessageListType, index: any, self: any[]) =>
          index === self.findIndex(t => t.id === value.id)
      )
    } else {
      filteredSelectedMessages =
        context.messagesActionWapper.selectedMessages.filter(m => m.id !== message.id) || []
    }
    context.selectMessages(filteredSelectedMessages)
  }

  const selectMessageAction = (event: any) => {
    // const action = Object.values(actions).find((a) => a.key === event.target.value?.value)
    const action = actions?.find(a => a.key === event.target.value?.value)

    if (!action) return

    context.selectMessageAction(action)
  }

  const executeMessageAction = () => {
    const messageArgs = context.messagesActionWapper.action?.definition.args
    if (messageArgs?.length) {
      setIsActionArgsModalOpened(true)
    } else {
      context.executeMessageAction()
    }
  }

  const actionsAsOptions = () => actions?.map(a => ({ label: a.name, value: a.key })) || []

  const handleSwitchMessageByArrow = (event: React.KeyboardEvent) => {
    const isArrowUp = event.key === 'ArrowUp'
    const isArrowDown = event.key === 'ArrowDown'

    if (!isArrowDown && !isArrowUp) return
    if (!showQuickView) return

    event.preventDefault()

    analytics.event({
      category: AnalyticsEvent.MessageDashboardListNavigateBetween,
      action: AnalyticsEventTrigger.keydown,
    })

    if (context.messageDetails.isLoading) return

    const messageCollection = context.messages.list || []
    const currentIndex = messageCollection.findIndex(p => p.id === messageQuickView?.id)

    if (currentIndex < 0) return

    const canGoDown = currentIndex < messageCollection.length - 1
    const canGoUp = currentIndex > 0
    const nextIndex =
      isArrowDown && canGoDown
        ? currentIndex + 1
        : isArrowUp && canGoUp
        ? currentIndex - 1
        : currentIndex
    setMessageQuickView(messageCollection[nextIndex])
    context.selectMessages([messageCollection[nextIndex]])
  }

  const handleHideQuickViewMessage = (event: React.KeyboardEvent) => {
    if (event.code === 'Escape') {
      setShowQuickView(false)
      event.preventDefault()
    }
  }

  const ActionField = () => (
    <Field className="flex w-full">
      <h2 className="flex text-base font-bold">
        Actions
        {Boolean(context.messagesActionWapper.selectedMessages) && (
          <span className="pl-2 text-sm font-normal text-neutral">
            ({context.messagesActionWapper.selectedMessages.length} messages)
          </span>
        )}
      </h2>
      <div className="flex">
        <Select
          id="select-action"
          name="select-action"
          className="flex-1"
          options={actionsAsOptions()}
          onChange={event => {
            analytics.event({
              category: AnalyticsEvent.MessageDashboardActionsSelectAction,
              action: AnalyticsEventTrigger.click,
            })
            selectMessageAction(event)
          }}
          value={
            context.messagesActionWapper.action?.key
              ? ({
                  label: context.messagesActionWapper.action?.name,
                  value: context.messagesActionWapper.action?.key,
                } as Selectable)
              : null
          }
        />
        <div className="flex items-center ml-4">
          <Button
            leading={<Icon name="bolt" className="flex ml-2" />}
            id="execute-action"
            onClick={() => {
              analytics.event({
                category: AnalyticsEvent.MessageDashboardActionsApplyAction,
                action: AnalyticsEventTrigger.click,
                params: {
                  ...context.messagesActionWapper.action,
                },
              })
              executeMessageAction()
            }}
          />
        </div>
      </div>
    </Field>
  )

  const MessageCounter = () => {
    const rawMessageCount = context.messages.count || 0
    const messageCountLabel =
      rawMessageCount >= MAX_MESSAGE_COUNT_VALUE ? MAX_MESSAGE_COUNT_LABEL : String(rawMessageCount)
    return (
      <Layout.Box data-testid="message-list-counter" padding="none">
        <Text className="mr-2">{messageCountLabel}</Text>
        <Text className="">messages</Text>
      </Layout.Box>
    )
  }

  const COLUMNS: any = [
    {
      id: 'selection',
      Header: (
        <Grid justifyContent={'center'} alignContent={'center'}>
          <Checkbox
            id="select-all-message"
            onChange={onSelectAllMessages}
            checked={messagesAsList.length === context.messagesActionWapper.selectedMessages.length}
          />
        </Grid>
      ),
      Cell: (row: MessageListType) => (
        <Grid justifyContent={'center'}>
          <Checkbox
            onChange={e => {
              onSelectedMessage(e, row)
            }}
            checked={isMessageSelected(row.id || '')}
          />
        </Grid>
      ),
      width: '30px',
    },
    {
      id: 'id',
      Header: '#',
      Cell(row: MessageListType) {
        const messageIdAsString = row.id?.slice(0, 8) || '-'

        return (
          <a href={`/messages/${row.id}`} onClick={e => e.preventDefault()}>
            <Tag
              variant="default"
              onClick={e => {
                analytics.event({
                  category: AnalyticsEvent.MessageDashboardListOpenDetail,
                  action: AnalyticsEventTrigger.click,
                })
                setMessageQuickView(row)
                setShowQuickView(true)
              }}
              onAuxClick={e => {
                window.open(`/messages/${row.id}`, '_blank')
              }}
              className="cursor-pointer"
            >
              {messageIdAsString}
            </Tag>
          </a>
        )
      },
      width: '100px',
    },
    {
      id: 'party',
      Header: 'Party',
      Cell(row: MessageListType) {
        const sourceName = row.party?.name || ''
        return <TradingPartnerTag id={row.party?.id || ''} name={sourceName} label="Party" />
      },
      width: '140px',
    },
    {
      id: 'direction',
      Header: '',
      Cell(row: MessageListType) {
        return <DirectionTag direction={row.direction} />
      },
      width: '60px',
    },
    {
      id: 'counterparty',
      Header: 'Counter Party',
      Cell(row: MessageListType) {
        const targetName = row.counterparty?.name || ''
        return (
          <TradingPartnerTag
            id={row.counterparty?.id || ''}
            name={targetName}
            label="Counterparty"
          />
        )
      },
      width: '140px',
    },
    {
      id: 'transaction_type',
      Header: 'Transaction Type',
      Cell(row: MessageListType) {
        return <TransactionTypeTag transaction_type={row.transaction_type} />
      },
      width: '230px',
    },
    {
      id: 'party_reference',
      Header: 'Party Reference',
      Cell(row: MessageListType) {
        const partyReference = formatMessageReference(row.party_reference)
        return partyReference ? <ClipboardLabel value={partyReference} /> : null
      },
      width: '180px',
    },
    {
      id: 'counterparty_reference',
      Header: 'C.Party Reference',
      Cell(row: MessageListType) {
        const counterpartyReference = formatMessageReference(row.counterparty_reference)
        return counterpartyReference ? (
          <Tooltip title={row.counterparty_reference}>
            <ClipboardLabel value={counterpartyReference} />
          </Tooltip>
        ) : null
      },
      width: '180px',
    },
    {
      id: 'created_at',
      Header: 'Created At',
      alignment: 'right',
      width: '100px',
      Cell(row: MessageListType) {
        return <DateTimezoneLabel date={row.created_at} className="text-warning-light" />
      },
    },
    {
      id: 'updated_at',
      Header: 'Updated At',
      alignment: 'right',
      width: '100px',
      Cell(row: MessageListType) {
        return <DateTimezoneLabel date={row.updated_at} className="text-warning-light" />
      },
    },
    {
      id: 'status',
      Header: 'Status',
      alignment: 'right',
      width: '160px',
      Cell(row: MessageListType) {
        return (
          <Layout.Group space="s" justify="flex-end">
            <MessageDeliveredStatusCompoonent delivered={row.delivered} />
            {row.status ? <MessageStatusTag status={row.status} /> : null}
          </Layout.Group>
        )
      },
    },
  ]

  // Static references
  handleHideQuickViewRef.current = handleHideQuickViewMessage
  handleSwitchMessageByArrowRef.current = handleSwitchMessageByArrow

  const [visibleColumns, setVisibleColumns] = useState([COLUMNS.map((column: any) => column.id)])

  const recalculateColumns = () => {
    const screenWidth = window.innerWidth
    if (screenWidth < 1400) {
      setVisibleColumns([
        'selection',
        'id',
        'party',
        'direction',
        'counterparty',
        'transaction_type',
        'created_at',
        'status',
      ])
    } else if (screenWidth < 1600) {
      setVisibleColumns([
        'selection',
        'id',
        'party',
        'direction',
        'counterparty',
        'transaction_type',
        'party_reference',
        'counterparty_reference',
        'created_at',
        'status',
      ])
    } else {
      setVisibleColumns([...COLUMNS.map((column: any) => column.id)])
    }
  }

  useEffect(() => {
    recalculateColumns()
    window.addEventListener('resize', recalculateColumns)
    return () => {
      window.removeEventListener('resize', recalculateColumns)
    }
  }, [])

  useEffect(() => {
    const handleKeyDown = (e: any) => {
      if (handleSwitchMessageByArrowRef.current) handleSwitchMessageByArrowRef.current(e)
      if (handleHideQuickViewRef.current) handleHideQuickViewRef.current(e)
    }
    window.addEventListener('keydown', e => handleKeyDown(e))

    // Callback didMount: remove listener
    return () => {
      window.removeEventListener('keydown', e => handleKeyDown(e))
    }
  }, [])

  useEffect(() => {
    setActionFeedbackOpen(context.messagesActionWapper.response ? true : false)
  }, [context.messagesActionWapper.response])

  return (
    <Box data-testid="message-list-container">
      <MessageActionCallResponse
        open={actionFeedbackOpen}
        onClose={() => setActionFeedbackOpen(false)}
        response={context.messagesActionWapper.response}
      />

      <Dialog
        open={isActionArgsModalOpened}
        onOverlayClick={() => setIsActionArgsModalOpened(false)}
      >
        <Dialog.Header>Action arguments</Dialog.Header>
        <Dialog.Body>
          <Layout.Stack>
            {context.messagesActionWapper.action?.definition.args.map(arg => (
              <Field className="flex-1 w-full pb-4 lg:pb-0 lg:pr-4" key={arg.key}>
                <Label>{arg.description}</Label>
                <TextField
                  value={actionArgs[arg.key] || ''}
                  onChange={(e: EventLike<any>) => {
                    setActionArgs(prevState => ({
                      ...prevState,
                      [arg.key]: e.target.value,
                    }))
                  }}
                />
              </Field>
            ))}
          </Layout.Stack>
        </Dialog.Body>
        <Dialog.ActionConfirm
          onConfirm={() => {
            context.executeMessageAction(actionArgs)
          }}
        />
        <Dialog.ActionCancel onCancel={() => setIsActionArgsModalOpened(false)} />
      </Dialog>

      <MessageQuickView
        open={showQuickView}
        messageId={messageQuickView?.id || ''}
        onClose={() => setShowQuickView(false)}
      />

      <Stack spacing={1} sx={{ width: '100%' }}>
        <Grid container justifyContent={'space-between'} alignItems={'end'}>
          <Grid item md={5}>
            <ActionField />
          </Grid>
          <Grid item>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
              {isTrafficOverviewEnabled && <MessageVolumeSparkline />}
              <MessageCounter />
            </Box>
          </Grid>
        </Grid>

        <TableContainer id="table" component={Paper} variant="outlined">
          {<LinearProgress style={{ opacity: loading ? '100%' : '0%' }} />}
          <Table
            data-testid="message-list"
            size="small"
            sx={{
              tableLayout: 'fixed',
              width: '100%',
              maxWidth: '100%',
              // whiteSpace: 'nowrap',
            }}
          >
            <TableHead>
              <TableRow>
                {COLUMNS.filter((c: any) => visibleColumns.includes(c.id)).map((column: any) => (
                  <TableCell
                    key={typeof column.Header === 'string' ? column.Header : column.id}
                    // align={column.alignment}
                    style={{
                      width: column.width,
                    }}
                    sx={{
                      width: column.width,
                    }}
                  >
                    {column.Header}
                  </TableCell>
                ))}
              </TableRow>
            </TableHead>
            <TableBody>
              {messagesAsList &&
                messagesAsList.map((row, index) => {
                  let tableRowStyle: any = {
                    borderInlineStartWidth: 4,
                    borderInlineStartColor: Themes.Loadsmart['color-neutral-white'],
                  }

                  if (row.id === messageQuickView?.id) {
                    tableRowStyle = {
                      borderInlineWidth: 4,
                      borderInlineStartColor: Themes.Loadsmart['color-primary'],
                    }
                  }

                  tableRowStyle = {
                    ...tableRowStyle,
                    backgroundColor: index % 2 ? theme.palette.grey[100] : '',
                  }

                  return (
                    <TableRow key={row.id} style={tableRowStyle} sx={tableRowStyle}>
                      {COLUMNS.filter((c: any) => visibleColumns.includes(c.id)).map(
                        (cell: any) => (
                          <TableCell className={clsx('text-sm')} key={`${row?.id}-${cell.Header}`}>
                            {cell.Cell(row)}
                          </TableCell>
                        )
                      )}
                    </TableRow>
                  )
                })}
            </TableBody>
          </Table>
        </TableContainer>

        {messagesAsList.length === 0 && (
          <div className="w-full p-4 text-center bg-neutral-lightest text-neutral-darkest text-sm">
            No messages to display
          </div>
        )}

        <SimplePagination
          count={context.messages.count}
          onPage={onPage}
          canNextPage={context.messages.canNextPage}
          canPreviousPage={context.messages.canPreviousPage}
          className="w-full px-4 pt-6"
          currentPage={currentPage()}
          nextPage={nextPage}
          previousPage={previousPage}
          pageSize={context.messagesPageRequest.size}
          onPageSize={onPageSize}
        />
      </Stack>
    </Box>
  )
}
