import { AxiosError, AxiosRequestConfig, AxiosResponse } from 'axios'
import { to } from 'common/helpers/awaitTo'
import { get, post, put } from 'common/HTTPClient'
import {
  MessageHistoryRead,
  MessageList,
  MessageLogRead,
  MessageRead,
  MessageReceiptRead,
  MessageFailureRead,
  TransactionType,
  MessageStatus,
  MessageStatusTag,
} from 'common/types/kraken-core/Message'
import { PaginatedRequest } from 'common/types/kraken-core/Pagination'
import { TrafficRequest, TrafficResponse } from 'common/types/kraken-core/TrafficVolume'

export const MESSAGE_SEARCH_MAX_COUNT = 10000

export interface GetPaginatedMessagesPayload {
  next: string
  previous: number
  results: MessageList[]
  count: number
}

export interface SearchMessagesFilters {
  partnerId?: string
  partyId?: string
  counterpartyId?: string
  reference?: string
  delivered?: string
  createdDateStart?: string
  createdDateEnd?: string
  updatedDateStart?: string
  updatedDateEnd?: string
  transactionType?: string
  excludeTransactionType?: string
  direction?: string
  page?: number
  pageSize?: number
  exclude_last_step?: string
  last_step?: string
  status?: string
  status_tags?: string
  installationId?: string
  integrationId?: string
  pair_of_partners?: boolean
}

export interface SearchTransactionTypesFilters extends PaginatedRequest {
  key?: string
  description?: string
}

export interface SearchMessageStatusChoicesRequest extends PaginatedRequest {
  status?: string
  description?: string
}

export interface GetTransactionTypesPayload {
  next: string
  previous: number
  count: number
  results: TransactionType[]
}

export interface GetMessageActionPayload {
  key: string
  description: string
}

export interface ExecuteMessageActionPayload {
  key: string
  messages: Array<string>
  arguments: Record<string, string>
}

export interface UpdateMessageBodyPayload {
  id: string
  content: string
  contentType: string
}

export interface UpdateMessageHeaders {
  id: string
  headers: any
}

export type ReadMessagePayload = MessageRead

export async function getMessages(
  filter?: SearchMessagesFilters,
  config?: AxiosRequestConfig
): Promise<[AxiosError | null, AxiosResponse<GetPaginatedMessagesPayload> | undefined]> {
  let error = null
  let result
  try {
    const params = {
      page: filter?.page,
      page_size: filter?.pageSize,
      partner: filter?.partnerId,
      party_id: filter?.partyId,
      counterparty_id: filter?.counterpartyId,
      reference: filter?.reference,
      direction: filter?.direction,
      created_at__gte: filter?.createdDateStart,
      created_at__lte: filter?.createdDateEnd,
      updated_at__gte: filter?.updatedDateStart,
      updated_at__lte: filter?.updatedDateEnd,
      transaction_type: filter?.transactionType,
      exclude_transaction_type: filter?.excludeTransactionType,
      delivered: filter?.delivered,
      last_step: filter?.last_step,
      exclude_last_step: filter?.exclude_last_step,
      status: filter?.status,
      status_tags: filter?.status_tags,
      installation_id: filter?.installationId,
      integration_id: filter?.integrationId,
    }
    ;[error, result] = await to(get('/search/messages', { ...config, params }))
    return [error, result]
  } catch (e) {
    console.error(e)
  }

  return [error, result]
}

export async function readMessage(
  messageUuid?: string,
  config?: AxiosRequestConfig
): Promise<[AxiosError | null, AxiosResponse<ReadMessagePayload> | undefined]> {
  let error = null
  let result

  try {
    ;[error, result] = await to(get(`/messages/${messageUuid}`, config))

    // If message doesn't exist, try to restorem them
    if (error?.response?.status === 404) {
      await to(post(`/archived_messages/${messageUuid}/restore`, {}, config))
      ;[error, result] = await to(get(`/messages/${messageUuid}`, config))
    }
  } catch (e) {
    console.error(e)
  }

  return [error, result]
}

export async function readMessageHistory(
  messageUuid?: string,
  config?: AxiosRequestConfig
): Promise<[AxiosError | null, AxiosResponse<MessageHistoryRead[]> | undefined]> {
  let error = null
  let result
  try {
    ;[error, result] = await to(get(`/messages/${messageUuid}/history`, config))
    return [error, result]
  } catch (e) {
    console.error(e)
  }

  return [error, result]
}

export async function readMessageLogs(
  messageUuid?: string,
  config?: AxiosRequestConfig
): Promise<[AxiosError | null, AxiosResponse<MessageLogRead[]> | undefined]> {
  let error = null
  let result
  try {
    ;[error, result] = await to(get(`/messages/${messageUuid}/logs`, config))
    return [error, result]
  } catch (e) {
    console.error(e)
  }

  return [error, result]
}

export async function readMessageFailures(
  messageUuid?: string,
  config?: AxiosRequestConfig
): Promise<[AxiosError | null, AxiosResponse<MessageFailureRead[]> | undefined]> {
  let error = null
  let result
  try {
    ;[error, result] = await to(get(`/messages/${messageUuid}/failures`, config))
    return [error, result]
  } catch (e) {
    console.error(e)
  }

  return [error, result]
}

export async function readMessageReceipts(
  messageUuid?: string,
  config?: AxiosRequestConfig
): Promise<[AxiosError | null, AxiosResponse<MessageReceiptRead[]> | undefined]> {
  let error = null
  let result
  try {
    ;[error, result] = await to(get(`/messages/${messageUuid}/receipts`, config))
    return [error, result]
  } catch (e) {
    console.error(e)
  }

  return [error, result]
}

export async function searchTransactionTypes(
  filter: SearchTransactionTypesFilters,
  config?: AxiosRequestConfig
): Promise<[AxiosError | null, AxiosResponse<GetTransactionTypesPayload> | undefined]> {
  let error = null
  let result
  try {
    const params = {
      key: filter.key,
      description: filter.description,
      page_size: filter.page_size || 100,
    }
    ;[error, result] = await to(get('/transaction_types', { ...config, params }))
    return [error, result]
  } catch (e) {
    console.error(e)
  }

  return [error, result]
}

export async function getMessageActions(): Promise<
  [AxiosError | null, AxiosResponse<Array<GetMessageActionPayload>> | undefined]
> {
  let error = null
  let result
  try {
    ;[error, result] = await to(get('/actions'))
    return [error, result]
  } catch (e) {
    console.error(e)
  }

  return [error, result]
}

export async function executeMessageAction(
  action: ExecuteMessageActionPayload,
  config?: AxiosRequestConfig
): Promise<[AxiosError | null, AxiosResponse<Blob> | undefined]> {
  let error = null
  let result
  try {
    ;[error, result] = await to(post('/action/call', action, config))
    return [error, result]
  } catch (e) {
    console.error(e)
  }
  return [error, result]
}

export async function updateMessageBody(
  action: UpdateMessageBodyPayload,
  config?: AxiosRequestConfig
): Promise<[AxiosError | null, AxiosResponse<Blob> | undefined]> {
  try {
    const [error, result] = await to(
      put(
        `/messages/${action.id}`,
        {
          body: action.content,
          content_type: action.contentType,
        },
        config
      )
    )

    return [error, result]
  } catch (e) {
    return [e as AxiosError, undefined]
  }
}

export async function updateMessageHeaders(
  action: UpdateMessageHeaders,
  config?: AxiosRequestConfig
): Promise<[AxiosError | null]> {
  const [error] = await to(put(`/messages/${action.id}/headers`, action.headers, config))
  return [error]
}

export async function searchMessageStatusesChoises(
  filters?: SearchMessageStatusChoicesRequest,
  config?: AxiosRequestConfig
): Promise<[AxiosError | null, AxiosResponse<Array<MessageStatus>> | undefined]> {
  let params = {}
  if (filters)
    params = {
      status: filters?.status,
      description: filters?.description,
      page_size: filters?.page_size || 100,
    }
  const [error, result] = await to(get(`/messages_status_choices`, { ...config, params }))
  return [error, result]
}

export async function getMessageStatusTags(
  config?: AxiosRequestConfig
): Promise<[AxiosError | null, AxiosResponse<Array<MessageStatusTag>> | undefined]> {
  const [error, result] = await to(get(`/messages/status_tags`, { ...config }))
  return [error, result]
}

export async function getTraffic(
  params: TrafficRequest,
  config?: AxiosRequestConfig
): Promise<[AxiosError | null, AxiosResponse<Array<TrafficResponse>> | undefined]> {
  const filters: any = {
    partner: params.filters.partnerId,
    party_id: params.filters.partyId,
    counterparty_id: params.filters.counterpartyId,
    reference: params.filters.reference,
    direction: params.filters.direction,
    transaction_type: params.filters.transactionType,
    exclude_transaction_type: params.filters.excludeTransactionType,
    delivered: params.filters.delivered,
    last_step: params.filters.last_step,
    exclude_last_step: params.filters.exclude_last_step,
    status: params.filters.status,
    status_tags: params.filters.status_tags,
    installation_id: params.filters.installationId,
    integration_id: params.filters.integrationId,

    // Static filters
    from_date: params.from_date,
    to_date: params.to_date,
    timeframe: params.timeframe,
    aggregator: 'status',
  }

  // remove null values
  Object.keys(filters).forEach(key => {
    if (filters[key] === null) {
      delete filters[key]
    }
  })
  const [error, result] = await to(get('/search/traffic', { ...config, params: filters }))
  return [error, result]
}
