// eslint-disable-next-line no-use-before-define
import React from 'react'
import { Stack } from '@mui/material'
import MessageDashboardOverview from './MessageDashboardOverviewContent'
import MessageList from './MessageList'
import { FEATURE_FLAGS, useAppContext } from 'hooks/useUserContext/appUserContext'

export interface MessageDashboardViewContentProps {
  className?: string
}

function MessageDashboardViewContent({ className }: MessageDashboardViewContentProps) {
  const appContext = useAppContext()
  const showOverviewDashboard = FEATURE_FLAGS.TRAFFIC_OVERVIEW.users.includes(
    appContext.user?.id || ''
  )

  return (
    <Stack sx={{ pr: 2 }}>
      {showOverviewDashboard && <MessageDashboardOverview />}
      <MessageList />
    </Stack>
  )
}

export default MessageDashboardViewContent
