import Highcharts from 'highcharts/highstock'
import HighchartsReact from 'highcharts-react-official'
import { useEffect, useState, useRef } from 'react'
import { useMessagesDashboardViewContext } from 'pages/Messages/MessagesDashboardViewContext'
import { useTheme } from '@mui/material'

export interface MessageTrafficVolumeChartProps {}

export default function MessageTrafficVolumeChart(props: MessageTrafficVolumeChartProps) {
  const theme = useTheme()
  const messageViewContext = useMessagesDashboardViewContext()
  const [series, setSeries] = useState<any>()
  const chartComponentRef = useRef<HighchartsReact.RefObject>(null) // Add ref for HighchartsReact

  useEffect(() => {
    if (!messageViewContext.traffic) {
      return
    }

    chartComponentRef.current?.chart.showLoading()
    const series = new Map<string, Array<Array<any>>>()
    const summary: any = {} // Initialize summary object

    const contextTraffic = messageViewContext.traffic
    contextTraffic.traffic.buckets.forEach(bucket => {
      Object.keys(bucket.per_agg).forEach(key => {
        const timestamp = new Date(bucket.datetime).getTime() // Convert to timestamp
        if (!series.has(key)) {
          series.set(key, [[timestamp, bucket.per_agg[key]]])
        } else {
          const serie = series.get(key) as any
          series.set(key, [...serie, [timestamp, bucket.per_agg[key]]])
        }

        // Update summary
        if (!summary[key]) {
          summary[key] = bucket.per_agg[key]
        } else {
          summary[key] += bucket.per_agg[key]
        }
      })
    })

    const chartSeries: any = []
    series.forEach((value, key) => {
      const colorMapping: any = {
        procssing: '#7cb5ec',
        processed: '#33CC60',
        failed: theme.palette.error.main,
        ignored: theme.palette.warning.main,
        created: '#5e5e5e',
        archived: '#e4d354',
      }
      const color = colorMapping[String(key)] || '#2b908f'

      chartSeries.push({
        name: key,
        data: value,
        color,
      })
    })

    setSeries(chartSeries)

    // Reset chart zoom
    const chart = chartComponentRef.current?.chart
    if (chart) {
      chart.xAxis[0].setExtremes()
    }
    chartComponentRef.current?.chart.hideLoading()
  }, [messageViewContext.traffic])

  useEffect(() => {
    const chart = chartComponentRef.current?.chart
    if (messageViewContext.traffic?.isLoading) {
      chart?.showLoading() // Show loading indicator
    } else {
      chart?.hideLoading() // Hide loading indicator
    }
  }, [messageViewContext.traffic?.isLoading])

  const options = {
    chart: {
      type: 'column',
      style: {
        fontFamily: 'Arial, sans-serif', // Set default font
      },
      animation: false, // Disable animations
      height: 300,
    },
    time: {
      useUTC: false, // Use local time instead of UTC
      timezone: undefined, // Let the browser decide the timezone
    },
    title: {
      align: 'left',
    },
    xAxis: {
      // type: 'datetime', // Use datetime axis
    },
    yAxis: {
      min: 0,
      title: {
        text: 'Values',
      },
    },
    plotOptions: {
      column: {
        stacking: 'normal',
        dataLabels: {
          enabled: false, // Ensure data labels are not shown
        },
        dataGrouping: {
          enabled: true,
          approximation: 'sum',
          groupPixelWidth: 20,
        },
      },
    },
    credits: {
      enabled: false,
    },
    series: series,
  }

  return (
    <HighchartsReact
      ref={chartComponentRef} // Attach ref to HighchartsReact component
      constructorType={'stockChart'}
      highcharts={Highcharts}
      options={options}
    />
  )
}
