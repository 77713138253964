import { Box } from '@mui/material'
import MessageTrafficVolumeChart from 'molecules/Charts/MessageVolumeChart'
import { useEffect } from 'react'
import { useMessagesDashboardViewContext } from '../MessagesDashboardViewContext'

export interface MessageVDashboardOverviewProps {}

export default function MessageDashboardOverview(props: MessageVDashboardOverviewProps) {
  const context = useMessagesDashboardViewContext()

  useEffect(function init() {
    context.searchMessagesTraffic()
  }, [])

  return (
    <Box>
      <MessageTrafficVolumeChart />
    </Box>
  )
}
